import type { DateTimeScalar } from './scalars';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeScalar: { input: DateTimeScalar; output: DateTimeScalar; }
  Duration: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Point: { input: any; output: any; }
  Polygon: { input: any; output: any; }
}

export enum AccountType {
  Credit = 'CREDIT',
  Sales = 'SALES'
}

export interface AdMediaObject {
  __typename?: 'AdMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  mediaQuery: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface AddressObject {
  __typename?: 'AddressObject';
  alias?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  complex?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['Point']['output']>;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  type: AddressType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum AddressType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export interface AgreementDeclarationObject {
  __typename?: 'AgreementDeclarationObject';
  agreed: Scalars['Boolean']['output'];
  agreedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  agreement: AgreementObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export interface AgreementObject {
  __typename?: 'AgreementObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  translations: Array<AgreementTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface AgreementTranslationObject {
  __typename?: 'AgreementTranslationObject';
  agreement: AgreementObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface AuthRoleObject {
  __typename?: 'AuthRoleObject';
  allChannels: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface BulkOrderEntryObject {
  __typename?: 'BulkOrderEntryObject';
  bulkOrder: BulkOrderObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  order: OrderObject;
  orderTemplateAppliedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  orderTemplateVersion: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface BulkOrderObject {
  __typename?: 'BulkOrderObject';
  authorUser?: Maybe<UserObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  customerUser?: Maybe<UserObject>;
  entries: Array<BulkOrderEntryObject>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  template: OrderTemplateObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ChannelLocaleObject {
  __typename?: 'ChannelLocaleObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  locale: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ChannelObject {
  __typename?: 'ChannelObject';
  code: Scalars['String']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  defaultCollectionPoint?: Maybe<CollectionPointObject>;
  defaultCountry: Scalars['String']['output'];
  defaultLocale: Scalars['String']['output'];
  defaultTaxCategory?: Maybe<TaxCategoryObject>;
  defaultWarehouse?: Maybe<WarehouseObject>;
  enabled: Scalars['Boolean']['output'];
  enquiryOnly: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  locales: Array<ChannelLocaleObject>;
  name: Scalars['String']['output'];
  regions: Array<GeoRegionObject>;
  rootTaxon?: Maybe<TaxonObject>;
  translations: Array<ChannelTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouses: Array<WarehouseObject>;
}

export interface ChannelTranslationObject {
  __typename?: 'ChannelTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta?: Maybe<MetaContentObject>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface CollectionPointObject {
  __typename?: 'CollectionPointObject';
  address: AddressObject;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  method: ShippingMethodObject;
  name: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum CouponType {
  Bulk = 'BULK',
  Single = 'SINGLE'
}

export interface DateRangeFilterInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  expand?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
}

export interface DimensionsObject {
  __typename?: 'DimensionsObject';
  height: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
}

export interface FulfilmentObject {
  __typename?: 'FulfilmentObject';
  allocatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  cancelledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  date?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  order: OrderObject;
  packedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  reservedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: FulfilmentState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse?: Maybe<WarehouseObject>;
}

export enum FulfilmentState {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  New = 'NEW',
  Packed = 'PACKED',
  Reserved = 'RESERVED',
  Unallocated = 'UNALLOCATED'
}

export interface GeoRegionDefinitionObject {
  __typename?: 'GeoRegionDefinitionObject';
  boundary?: Maybe<Scalars['Polygon']['output']>;
  center?: Maybe<Scalars['Point']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  include: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  region: GeoRegionObject;
  suburb?: Maybe<Scalars['String']['output']>;
  type: GeoRegionDefinitionType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum GeoRegionDefinitionType {
  Address = 'ADDRESS',
  Boundary = 'BOUNDARY',
  Postcode = 'POSTCODE'
}

export interface GeoRegionObject {
  __typename?: 'GeoRegionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  definitions: Array<GeoRegionDefinitionObject>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<GeoRegionObject>;
  primaryChannel: ChannelObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface MemberObject {
  __typename?: 'MemberObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  deactivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  expiryDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  firstActivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  membership: MembershipObject;
  pointBalance: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: MemberState;
  subscriber?: Maybe<SubscriberObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export enum MemberState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  New = 'NEW'
}

export interface MembershipObject {
  __typename?: 'MembershipObject';
  activationUrl?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  members: Array<MemberObject>;
  name: Scalars['String']['output'];
  subscriptionBenefits: Array<SubscriptionMembershipBenefitObject>;
  taxons: Array<TaxonObject>;
  translations: Array<MembershipTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface MembershipTranslationObject {
  __typename?: 'MembershipTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  membership: MembershipObject;
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  renewalMessage?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface MetaContentObject {
  __typename?: 'MetaContentObject';
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  deleteSageOrderSync: SageOrderSyncObject;
  syncSageItemSync: SageItemSyncObject;
  syncSageOrderSync: SageOrderSyncObject;
  updateSageItemSync: SageItemSyncObject;
  updateSageOrderSync: SageOrderSyncObject;
}


export interface MutationDeleteSageOrderSyncArgs {
  number: Scalars['String']['input'];
}


export interface MutationSyncSageItemSyncArgs {
  sku: Scalars['String']['input'];
}


export interface MutationSyncSageOrderSyncArgs {
  number: Scalars['String']['input'];
}


export interface MutationUpdateSageItemSyncArgs {
  input: UpdateSageStockItemSyncInput;
}


export interface MutationUpdateSageOrderSyncArgs {
  input: UpdateSageOrderSyncInput;
}

export interface OrderAdjustmentObject {
  __typename?: 'OrderAdjustmentObject';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  type: OrderAdjustmentType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum OrderAdjustmentType {
  Custom = 'CUSTOM',
  PromotionDiscount = 'PROMOTION_DISCOUNT',
  ShippingDiscount = 'SHIPPING_DISCOUNT',
  ShippingFee = 'SHIPPING_FEE',
  ShippingTax = 'SHIPPING_TAX'
}

export interface OrderItemContextObject {
  __typename?: 'OrderItemContextObject';
  associationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  objectId?: Maybe<Scalars['String']['output']>;
  orderItem: OrderItemObject;
  origin?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  queryId?: Maybe<Scalars['String']['output']>;
  queryIndex?: Maybe<Scalars['String']['output']>;
  shopAdId?: Maybe<Scalars['Int']['output']>;
  shopAdPlacement?: Maybe<Scalars['String']['output']>;
  taxonId?: Maybe<Scalars['Int']['output']>;
}

export interface OrderItemObject {
  __typename?: 'OrderItemObject';
  accountType: AccountType;
  adjustments: Array<OrderAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  context?: Maybe<OrderItemContextObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  creditTotal: Scalars['Int']['output'];
  fixedUnitPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  order: OrderObject;
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  salesTotal: Scalars['Int']['output'];
  source: OrderItemSource;
  taxAmount: Scalars['Int']['output'];
  taxRate: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
  units: Array<OrderItemUnitObject>;
  unitsTotal: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
  variantName?: Maybe<Scalars['String']['output']>;
}

export enum OrderItemSource {
  Order = 'ORDER',
  Promotional = 'PROMOTIONAL',
  Shipping = 'SHIPPING',
  Subscription = 'SUBSCRIPTION'
}

export interface OrderItemUnitObject {
  __typename?: 'OrderItemUnitObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  stockItem?: Maybe<StockItemObject>;
  stockLot?: Maybe<StockLotObject>;
  stockLotRef?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderNoteObject {
  __typename?: 'OrderNoteObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  authorUser?: Maybe<UserObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderObject {
  __typename?: 'OrderObject';
  adjustments: Array<OrderAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  authorUser?: Maybe<UserObject>;
  balance: Scalars['Int']['output'];
  billingAddress?: Maybe<AddressObject>;
  bulkOrderEntry?: Maybe<BulkOrderEntryObject>;
  canceledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channel: ChannelObject;
  checkoutAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  collectionPoint?: Maybe<CollectionPointObject>;
  completedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  creditItemsTotal: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  deferAllocation: Scalars['Boolean']['output'];
  enquiredAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  fulfilment?: Maybe<FulfilmentObject>;
  giftMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isGift: Scalars['Boolean']['output'];
  items: Array<OrderItemObject>;
  itemsTotal: Scalars['Int']['output'];
  lastProcessedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  notes: Array<OrderNoteObject>;
  number?: Maybe<Scalars['String']['output']>;
  originalOrder?: Maybe<OrderObject>;
  paidTotal: Scalars['Int']['output'];
  paymentState: OrderPaymentState;
  payments: Array<PaymentObject>;
  printedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  processingAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  promotions: Array<PromotionObject>;
  quotedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  refundedTotal: Scalars['Int']['output'];
  salesItemsTotal: Scalars['Int']['output'];
  shipment?: Maybe<ShipmentObject>;
  shippingAddress?: Maybe<AddressObject>;
  shippingAddressGeoRegion?: Maybe<GeoRegionObject>;
  skipAgreements: Scalars['Boolean']['output'];
  source?: Maybe<Scalars['String']['output']>;
  state: OrderState;
  subscriptionEntry?: Maybe<SubscriptionEntryObject>;
  tags: Array<OrderTagObject>;
  taxTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  user?: Maybe<UserObject>;
  walletTransactions: Array<WalletTransactionObject>;
}

export enum OrderPaymentState {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Cancelled = 'CANCELLED',
  Cart = 'CART',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Refunded = 'REFUNDED',
  Skipped = 'SKIPPED'
}

export enum OrderState {
  Attention = 'ATTENTION',
  Cancelled = 'CANCELLED',
  Cart = 'CART',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Enquiry = 'ENQUIRY',
  Expired = 'EXPIRED',
  Processing = 'PROCESSING',
  Quoted = 'QUOTED'
}

export interface OrderTagObject {
  __typename?: 'OrderTagObject';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderTemplateItemObject {
  __typename?: 'OrderTemplateItemObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  template: OrderTemplateObject;
  unitPrice?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface OrderTemplateObject {
  __typename?: 'OrderTemplateObject';
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  items: Array<OrderTemplateItemObject>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  version: Scalars['Int']['output'];
}

export interface PageInput {
  index?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
}

export interface ParcelOptionObject {
  __typename?: 'ParcelOptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  dimensions?: Maybe<DimensionsObject>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
}

export interface PaymentMethodObject {
  __typename?: 'PaymentMethodObject';
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface PaymentObject {
  __typename?: 'PaymentObject';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  method: PaymentMethodObject;
  order: OrderObject;
  providerDescription?: Maybe<Scalars['String']['output']>;
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  reference: Scalars['String']['output'];
  refundedAmount: Scalars['Int']['output'];
  refundedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  save: Scalars['Boolean']['output'];
  state: PaymentState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  userMethod?: Maybe<UserPaymentMethodObject>;
}

export enum PaymentState {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  New = 'NEW',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Processing = 'PROCESSING',
  Redemption = 'REDEMPTION',
  Refunded = 'REFUNDED'
}

export interface ProductAssociatedProductObject {
  __typename?: 'ProductAssociatedProductObject';
  associatedProduct: ProductObject;
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  product: ProductObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAssociationConfigObject {
  __typename?: 'ProductAssociationConfigObject';
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  product: ProductObject;
  sourceMainTaxonEnable?: Maybe<Scalars['Boolean']['output']>;
  sourceTaxon?: Maybe<TaxonObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAssociationObject {
  __typename?: 'ProductAssociationObject';
  channels: Array<ChannelObject>;
  configs: Array<ProductAssociationConfigObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  productAssociations: Array<ProductAssociatedProductObject>;
  sourceMainTaxonEnable: Scalars['Boolean']['output'];
  sourceTaxon?: Maybe<TaxonObject>;
  targetMainTaxonEnable: Scalars['Boolean']['output'];
  targetTaxons: Array<TaxonObject>;
  targets?: Maybe<Array<ProductAssociationTarget>>;
  title: Scalars['String']['output'];
  translations: Array<ProductAssociationTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum ProductAssociationTarget {
  Cart = 'CART',
  Checkout = 'CHECKOUT',
  ProductDetail = 'PRODUCT_DETAIL'
}

export interface ProductAssociationTranslationObject {
  __typename?: 'ProductAssociationTranslationObject';
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAttributeObject {
  __typename?: 'ProductAttributeObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  translations: Array<ProductAttributeTranslationObject>;
  type: ProductAttributeType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAttributeTranslationObject {
  __typename?: 'ProductAttributeTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum ProductAttributeType {
  Boolean = 'BOOLEAN',
  Markdown = 'MARKDOWN',
  Number = 'NUMBER',
  Text = 'TEXT'
}

export interface ProductAttributeValueObject {
  __typename?: 'ProductAttributeValueObject';
  attribute: ProductAttributeObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['JSON']['output'];
}

export interface ProductMediaObject {
  __typename?: 'ProductMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  variant?: Maybe<ProductVariantObject>;
  width?: Maybe<Scalars['Int']['output']>;
}

export interface ProductObject {
  __typename?: 'ProductObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  attributeValues: Array<ProductAttributeValueObject>;
  attributes: Array<ProductAttributeObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mainMedia?: Maybe<ProductMediaObject>;
  mainTaxon: TaxonObject;
  media: Array<ProductMediaObject>;
  name: Scalars['String']['output'];
  options: Array<ProductOptionObject>;
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  secondaryMedia?: Maybe<ProductMediaObject>;
  taxons: Array<TaxonObject>;
  translations: Array<ProductTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<ProductVariantObject>;
  variation: ProductVariation;
}

export interface ProductOptionObject {
  __typename?: 'ProductOptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  products: Array<ProductObject>;
  translations: Array<ProductOptionTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  values: Array<ProductOptionValueObject>;
}

export interface ProductOptionTranslationObject {
  __typename?: 'ProductOptionTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductOptionValueObject {
  __typename?: 'ProductOptionValueObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  option: ProductOptionObject;
  position: Scalars['Int']['output'];
  translations: Array<ProductOptionValueTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductOptionValueTranslationObject {
  __typename?: 'ProductOptionValueTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductPositionObject {
  __typename?: 'ProductPositionObject';
  position: Scalars['Int']['output'];
  product: ProductObject;
  taxon: TaxonObject;
}

export interface ProductTranslationObject {
  __typename?: 'ProductTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  product: ProductObject;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductVariantAdmissionProductObject {
  __typename?: 'ProductVariantAdmissionProductObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface ProductVariantChannelObject {
  __typename?: 'ProductVariantChannelObject';
  agreements?: Maybe<Array<AgreementObject>>;
  available: Scalars['Boolean']['output'];
  availableRegions: Array<GeoRegionObject>;
  capAmount?: Maybe<Scalars['Int']['output']>;
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  taxCategory?: Maybe<TaxCategoryObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductVariantFeedOptionsObject {
  __typename?: 'ProductVariantFeedOptionsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  customLabel0?: Maybe<Scalars['String']['output']>;
  customLabel1?: Maybe<Scalars['String']['output']>;
  customLabel2?: Maybe<Scalars['String']['output']>;
  googleProductCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface ProductVariantObject {
  __typename?: 'ProductVariantObject';
  accountType: AccountType;
  admissionProduct?: Maybe<ProductVariantAdmissionProductObject>;
  channels: Array<ProductVariantChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  feedOptions?: Maybe<ProductVariantFeedOptionsObject>;
  id: Scalars['Int']['output'];
  media: Array<ProductMediaObject>;
  name: Scalars['String']['output'];
  optionValues: Array<ProductOptionValueObject>;
  product: ProductObject;
  stockItem?: Maybe<StockItemObject>;
  translations: Array<ProductVariantTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  voucherPrograms: Array<VoucherProgramObject>;
}

export interface ProductVariantTranslationObject {
  __typename?: 'ProductVariantTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export enum ProductVariation {
  Option = 'OPTION',
  Single = 'SINGLE'
}

export interface PromotionActionObject {
  __typename?: 'PromotionActionObject';
  configuration: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  promotion: PromotionObject;
  type: Scalars['String']['output'];
}

export interface PromotionObject {
  __typename?: 'PromotionObject';
  actions: Array<PromotionActionObject>;
  adjustmentLabel?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  couponBulkQty?: Maybe<Scalars['Int']['output']>;
  couponBulkSecret?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  couponType: CouponType;
  couponUsageLimit?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  customerUsageLimit?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  exclusive: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orders: Array<OrderObject>;
  priority: Scalars['Int']['output'];
  referrerCode?: Maybe<Scalars['String']['output']>;
  ruleExplanation?: Maybe<Scalars['String']['output']>;
  rules: Array<PromotionRuleObject>;
  startsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  usageLimit?: Maybe<Scalars['Int']['output']>;
}

export interface PromotionRuleObject {
  __typename?: 'PromotionRuleObject';
  configuration: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  promotion: PromotionObject;
  type: Scalars['String']['output'];
}

export interface Query {
  __typename?: 'Query';
  importSageItem: SageStockItemImportObject;
  sageItemSync: SageItemSyncObject;
  sageOrderSync: SageOrderSyncObject;
  sageOrderSyncPayload: Scalars['JSON']['output'];
  sageOrderSyncs: Array<SageOrderSyncObject>;
  sageOrderSyncsCount: Scalars['Int']['output'];
  sageWarehouse: Array<SageWarehouseObject>;
  sageWarehouseCount: Array<SageWarehouseObject>;
  sageWarehouses: Array<SageWarehouseObject>;
}


export interface QueryImportSageItemArgs {
  sku: Scalars['String']['input'];
}


export interface QuerySageItemSyncArgs {
  sku: Scalars['String']['input'];
}


export interface QuerySageOrderSyncArgs {
  number: Scalars['String']['input'];
}


export interface QuerySageOrderSyncPayloadArgs {
  number: Scalars['String']['input'];
}


export interface QuerySageOrderSyncsArgs {
  filter?: InputMaybe<SageOrderSyncFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QuerySageOrderSyncsCountArgs {
  filter?: InputMaybe<SageOrderSyncFilterInput>;
}


export interface QuerySageWarehouseArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySageWarehouseCountArgs {
  filter?: InputMaybe<SageWarehouseFilterInput>;
}


export interface QuerySageWarehousesArgs {
  filter?: InputMaybe<SageWarehouseFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}

export interface SageItemSyncObject {
  __typename?: 'SageItemSyncObject';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  error: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastSyncedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  lots: Array<SageStockLotSyncObject>;
  name?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  status: SageSyncStatus;
}

export interface SageOrderSyncFilterInput {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastSyncedAt?: InputMaybe<DateRangeFilterInput>;
  numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<SageSyncStatus>>;
}

export interface SageOrderSyncObject {
  __typename?: 'SageOrderSyncObject';
  createdAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  delete: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  diff?: Maybe<Scalars['JSON']['output']>;
  enabled: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastSyncedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  number: Scalars['String']['output'];
  state?: Maybe<Scalars['JSON']['output']>;
  status: SageSyncStatus;
  updatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
}

export interface SageStockItemImportObject {
  __typename?: 'SageStockItemImportObject';
  categoryName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  unit: Scalars['String']['output'];
}

export interface SageStockLotSyncObject {
  __typename?: 'SageStockLotSyncObject';
  enabled: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastSyncedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  lotId: Scalars['String']['output'];
  status: SageSyncStatus;
  warehouse: Scalars['Boolean']['output'];
}

export enum SageSyncStatus {
  Deleted = 'DELETED',
  Error = 'ERROR',
  InSync = 'IN_SYNC',
  OutSync = 'OUT_SYNC',
  Uneligible = 'UNELIGIBLE',
  Unsynced = 'UNSYNCED'
}

export interface SageWarehouseFilterInput {
  ids: Array<Scalars['Int']['input']>;
}

export interface SageWarehouseObject {
  __typename?: 'SageWarehouseObject';
  config?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  linkedWarehouseId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShipmentObject {
  __typename?: 'ShipmentObject';
  assignedSlotId?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  completedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  driver?: Maybe<Scalars['String']['output']>;
  errorDescription?: Maybe<Scalars['String']['output']>;
  fixedCost?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  labeledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  method?: Maybe<ShippingMethodObject>;
  methodLocked: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: OrderObject;
  parcels: Array<ShipmentParcelObject>;
  readyAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  requestedSlotEndAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requestedSlotId?: Maybe<Scalars['String']['output']>;
  requestedSlotStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requiredAddressType: AddressType;
  reservedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  routeSequence?: Maybe<Scalars['Int']['output']>;
  serviceOption?: Maybe<Scalars['String']['output']>;
  shippedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  slot?: Maybe<ShippingSlotObject>;
  slotLocked: Scalars['Boolean']['output'];
  state: ShipmentState;
  totalWeight: Scalars['Int']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShipmentParcelObject {
  __typename?: 'ShipmentParcelObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  dimensions?: Maybe<DimensionsObject>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  option?: Maybe<ParcelOptionObject>;
  reference?: Maybe<Scalars['String']['output']>;
  shipment: ShipmentObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
}

export enum ShipmentState {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  New = 'NEW',
  Pending = 'PENDING',
  Reserved = 'RESERVED',
  Shipped = 'SHIPPED',
  WaybillRequired = 'WAYBILL_REQUIRED'
}

export enum ShippingCategoryComparison {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
  IncludeOrNone = 'INCLUDE_OR_NONE'
}

export interface ShippingCategoryObject {
  __typename?: 'ShippingCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShippingMethodObject {
  __typename?: 'ShippingMethodObject';
  categories: Array<ShippingCategoryObject>;
  categoryComparison: ShippingCategoryComparison;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  collectionPoints: Array<CollectionPointObject>;
  collectionRequired: Scalars['Boolean']['output'];
  costCalculator?: Maybe<Scalars['String']['output']>;
  costCalculatorConfig?: Maybe<Scalars['JSON']['output']>;
  costProductVariant?: Maybe<ProductVariantObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  groupDestination: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  labelGenerator?: Maybe<Scalars['String']['output']>;
  labelGeneratorConfig?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  provider: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  quoteValidityDuration?: Maybe<Scalars['Duration']['output']>;
  regions: Array<GeoRegionObject>;
  schedules: Array<ShippingScheduleObject>;
  slotRequired: Scalars['Boolean']['output'];
  slots: Array<ShippingSlotObject>;
  stockType: StockType;
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
  warehouses: Array<ShippingWarehouseObject>;
}

export interface ShippingScheduleObject {
  __typename?: 'ShippingScheduleObject';
  capacity: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  duration: Scalars['Duration']['output'];
  enabled: Scalars['Boolean']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['String']['output'];
  leadTime?: Maybe<Scalars['Duration']['output']>;
  method: ShippingMethodObject;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  regionIds?: Maybe<Scalars['JSON']['output']>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShippingSlotObject {
  __typename?: 'ShippingSlotObject';
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  endAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['String']['output'];
  leadTime?: Maybe<Scalars['Duration']['output']>;
  method: ShippingMethodObject;
  priority: Scalars['Int']['output'];
  regionIds?: Maybe<Scalars['JSON']['output']>;
  schedule?: Maybe<ShippingScheduleObject>;
  startAt: Scalars['DateTimeScalar']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShippingWarehouseObject {
  __typename?: 'ShippingWarehouseObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SortInput {
  column: Scalars['String']['input'];
  /** Sort order asc|desc */
  order?: Scalars['String']['input'];
}

export interface StockCategoryObject {
  __typename?: 'StockCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface StockItemComponentObject {
  __typename?: 'StockItemComponentObject';
  componentItem: StockItemObject;
  componentQuantity: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  parentItem: StockItemObject;
}

export interface StockItemObject {
  __typename?: 'StockItemObject';
  category?: Maybe<StockCategoryObject>;
  components: Array<StockItemComponentObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  length?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  schedules: Array<StockScheduleObject>;
  shippingCategories: Array<ShippingCategoryObject>;
  sku: Scalars['String']['output'];
  tags: Array<StockCategoryObject>;
  type: StockType;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<ProductVariantObject>;
  warehouseOptions: Array<StockItemWarehouseOptionObject>;
  weight?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
}

export interface StockItemWarehouseOptionObject {
  __typename?: 'StockItemWarehouseOptionObject';
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  stockItem: StockItemObject;
  warehouse: WarehouseObject;
}

export interface StockLotObject {
  __typename?: 'StockLotObject';
  allocatedQuantity: Scalars['Int']['output'];
  availableAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['String']['output'];
  initialQuantity: Scalars['Int']['output'];
  item: StockItemObject;
  priority: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  releasedQuantity: Scalars['Int']['output'];
  reservedQuantity: Scalars['Int']['output'];
  schedule?: Maybe<StockScheduleObject>;
  tracked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
}

export interface StockScheduleObject {
  __typename?: 'StockScheduleObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['String']['output'];
  item: StockItemObject;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  shelfLife?: Maybe<Scalars['Duration']['output']>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  tracked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
}

export enum StockType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export interface SubscriberObject {
  __typename?: 'SubscriberObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  billingAddress?: Maybe<AddressObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  firstActivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  members: Array<MemberObject>;
  pausedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  shippingAddress?: Maybe<AddressObject>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: SubscriberState;
  tags: Array<SubscriberTagObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
  userPaymentMethod?: Maybe<UserPaymentMethodObject>;
  variant: SubscriptionVariantObject;
}

export enum SubscriberState {
  Active = 'ACTIVE',
  Cart = 'CART',
  Paused = 'PAUSED'
}

export interface SubscriberTagObject {
  __typename?: 'SubscriberTagObject';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionEntryObject {
  __typename?: 'SubscriptionEntryObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<OrderObject>;
  run: SubscriptionRunObject;
  subscriber: SubscriberObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionMembershipBenefitObject {
  __typename?: 'SubscriptionMembershipBenefitObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  duration?: Maybe<Scalars['Duration']['output']>;
  id: Scalars['Int']['output'];
  membership: MembershipObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: SubscriptionVariantObject;
}

export interface SubscriptionObject {
  __typename?: 'SubscriptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  translations: Array<SubscriptionTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<SubscriptionVariantObject>;
}

export enum SubscriptionRunInterval {
  Bimonthly = 'BIMONTHLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export interface SubscriptionRunObject {
  __typename?: 'SubscriptionRunObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  jobAction?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['String']['output']>;
  jobStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  runEnd: Scalars['DateTimeScalar']['output'];
  runStart: Scalars['DateTimeScalar']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: SubscriptionVariantObject;
}

export interface SubscriptionTranslationObject {
  __typename?: 'SubscriptionTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionVariantObject {
  __typename?: 'SubscriptionVariantObject';
  autoRenew: Scalars['Boolean']['output'];
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  duration?: Maybe<Scalars['Duration']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  interval: SubscriptionRunInterval;
  membershipBenefits: Array<SubscriptionMembershipBenefitObject>;
  name: Scalars['String']['output'];
  productQuantities: Array<SubscriptionVariantProductQuantityObject>;
  subscription: SubscriptionObject;
  translations: Array<SubscriptionVariantTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionVariantProductQuantityObject {
  __typename?: 'SubscriptionVariantProductQuantityObject';
  id: Scalars['Int']['output'];
  productVariant: ProductVariantObject;
  quantity: Scalars['Int']['output'];
  subscriptionVariant: SubscriptionVariantObject;
}

export interface SubscriptionVariantSmsObject {
  __typename?: 'SubscriptionVariantSmsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  translation: SubscriptionVariantTranslationObject;
  type: SubscriptionVariantSmsType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum SubscriptionVariantSmsType {
  Custom = 'CUSTOM',
  Deduction = 'DEDUCTION',
  Delivery = 'DELIVERY',
  Paid = 'PAID',
  PaymentProcessing = 'PAYMENT_PROCESSING',
  Unpaid = 'UNPAID'
}

export interface SubscriptionVariantTranslationObject {
  __typename?: 'SubscriptionVariantTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  smses?: Maybe<Array<SubscriptionVariantSmsObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxCategoryObject {
  __typename?: 'TaxCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxonAttributeValueObject {
  __typename?: 'TaxonAttributeValueObject';
  attribute: ProductAttributeObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  taxon: TaxonObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['JSON']['output'];
}

export interface TaxonFeedOptionsObject {
  __typename?: 'TaxonFeedOptionsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  customLabel0?: Maybe<Scalars['String']['output']>;
  customLabel1?: Maybe<Scalars['String']['output']>;
  customLabel2?: Maybe<Scalars['String']['output']>;
  googleProductCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxonMediaObject {
  __typename?: 'TaxonMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface TaxonObject {
  __typename?: 'TaxonObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  attributeValues: Array<TaxonAttributeValueObject>;
  attributes: Array<ProductAttributeObject>;
  badge: Scalars['Boolean']['output'];
  badgeIconUrl?: Maybe<Scalars['String']['output']>;
  children: Array<TaxonObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  feedOptions?: Maybe<TaxonFeedOptionsObject>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mainMedia?: Maybe<TaxonMediaObject>;
  mainProducts: Array<ProductObject>;
  media: Array<TaxonMediaObject>;
  name: Scalars['String']['output'];
  parent?: Maybe<TaxonObject>;
  path: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  productPositions: Array<ProductPositionObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface UpdateSageOrderSyncInput {
  enabled: Scalars['Boolean']['input'];
  number: Scalars['String']['input'];
}

export interface UpdateSageStockItemSyncInput {
  enabled: Scalars['Boolean']['input'];
  lots?: InputMaybe<Array<UpdateSageStockLotSyncInput>>;
  sku: Scalars['String']['input'];
}

export interface UpdateSageStockLotSyncInput {
  enabled: Scalars['Boolean']['input'];
  warehouseId: Scalars['Int']['input'];
}

export interface UserObject {
  __typename?: 'UserObject';
  addresses: Array<AddressObject>;
  agreements?: Maybe<Array<AgreementDeclarationObject>>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  birthday?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  defaultBillingAddress?: Maybe<AddressObject>;
  defaultChannel?: Maybe<ChannelObject>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  defaultShippingAddress?: Maybe<AddressObject>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  members?: Maybe<Array<MemberObject>>;
  phone?: Maybe<Scalars['String']['output']>;
  roles: Array<AuthRoleObject>;
  subscribers?: Maybe<Array<SubscriberObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface UserPaymentMethodObject {
  __typename?: 'UserPaymentMethodObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description: Scalars['String']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  method: PaymentMethodObject;
  reference: Scalars['String']['output'];
  reusable: Scalars['Boolean']['output'];
  state: UserPaymentMethodState;
  subscribers?: Maybe<Array<SubscriberObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum UserPaymentMethodState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  New = 'NEW',
  Processing = 'PROCESSING'
}

export interface VoucherProgramObject {
  __typename?: 'VoucherProgramObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productVariants: Array<ProductVariantObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['Int']['output'];
  voucherProviderId: Scalars['String']['output'];
}

export interface WalletObject {
  __typename?: 'WalletObject';
  balance: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  transactions: Array<WalletTransactionObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export interface WalletTransactionObject {
  __typename?: 'WalletTransactionObject';
  amount: Scalars['Int']['output'];
  balance: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<OrderObject>;
  state: WalletTransactionState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  wallet: WalletObject;
}

export enum WalletTransactionState {
  Credited = 'CREDITED',
  Debited = 'DEBITED',
  New = 'NEW'
}

export interface WarehouseObject {
  __typename?: 'WarehouseObject';
  address?: Maybe<AddressObject>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lots: Array<StockLotObject>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}
